import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "part-list-modal-wrapper" }
const _hoisted_2 = { class: "info" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["NModal"], {
    class: "part-list-modal",
    preset: "dialog",
    "show-icon": false,
    title: "Older version of this part:"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["NRadioGroup"], {
          value: $setup.selectedPartId,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedPartId) = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.parts, (part) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["part-list-modal-parts", {'active': $setup.selectedPartId === part.id}]),
                key: part.id
              }, [
                _createVNode($setup["NRadio"], {
                  value: part.id
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("p", _hoisted_3, [
                        _createElementVNode("span", null, "PN: " + _toDisplayString(part.number), 1),
                        _createElementVNode("span", null, " Rev " + _toDisplayString(part.rev), 1)
                      ]),
                      _createElementVNode("p", null, [
                        _createElementVNode("span", null, "Internal PN " + _toDisplayString(part.customerPN), 1),
                        _createElementVNode("span", null, " Rev " + _toDisplayString(part.customerRev), 1)
                      ]),
                      (part?.quoteRequestedBy)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                            _createElementVNode("span", null, "Quote requested by: " + _toDisplayString(part?.quoteRequestedBy), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (part?.orderedBy)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                            _createElementVNode("span", null, "Ordered by: " + _toDisplayString(part?.orderedBy), 1)
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1032, ["value"])
              ], 2))
            }), 128))
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode($setup["NButton"], {
          class: "update-button medium-button",
          type: "info",
          onClick: $setup.updatePart
        }, {
          default: _withCtx(() => [
            _createTextVNode("Use it")
          ]),
          _: 1
        }),
        _createVNode($setup["NButton"], {
          class: "medium-button n-button-cancel",
          type: "tertiary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Cancel")
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}