import { FormItemRule } from "naive-ui";
import { requiredMessage } from "@/composables/validationMessages";

const costingNumberVerificationRules = (field: string): FormItemRule[] => {
  return [
    {
      required: true,
      validator(_rule: FormItemRule, value: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
          if (!value || value === "null") {
            reject(Error(`*${requiredMessage(field)}`));
          } else if (Number(value) < 0) {
            reject(Error(`*Incorrect ${field} value. It can't be negative`));
          } else {
            resolve();
          }
        });
      },
      trigger: ["input", "blur"],
    },
  ];
};

export default costingNumberVerificationRules;
