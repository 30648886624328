import { FormItemRule } from "naive-ui";
import { requiredMessage } from "@/composables/validationMessages";

const costingStringVerificationRules = (field: string): FormItemRule[] => {
  return [
    {
      required: true,
      validator(_rule: FormItemRule, value: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
          if (!value) {
            reject(Error(`*${requiredMessage(field)}`));
          } else {
            resolve();
          }
        });
      },
      trigger: ["input", "blur"],
    },
  ];
};

export default costingStringVerificationRules;
