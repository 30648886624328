import { FormItemRule } from "naive-ui";
import { requiredMessage } from "@/composables/validationMessages";

const complexityCodeVerificationRules = {
  required: true,
  validator(_rule: FormItemRule, value: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!value && value !== 0) {
        reject(Error(`*${requiredMessage("Complexity")}`));
      } else {
        resolve();
      }
    });
  },
  trigger: ["input", "blur"],
};

export default complexityCodeVerificationRules;
