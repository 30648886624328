export enum UploadFileType {
  PO_ORDERS = "PO_ORDERS",
  CO_ORDERS = "CO_ORDERS",
  PARTS = "PARTS",
  PRODUCTION_PROCESS = "PRODUCTION_PROCESS",
  AVATARS = "avatars",
  RFQ_MODELS = "rfq_models",
  DRAWINGS = "drawings",
  RFQ_PREVIEWS = "rfq_previews",
  RFQ_CONVERTED_MODELS = "rfq_converted_models",
  RFQ_LIST = "rfq_list",
  RFQ_FILES = "rfq_files",
  FEEDBACK_DRAWINGS = "feedback_drawings",
  RFQ_PURCHASE_ORDER = "rfq_purchase_order",
  RFQ_ARCHIVES = "rfq_archives",
  QC_REPORTS = "qc_reports",
  CUSTOMER_LOGO = "customer_logo",
}
