import { FormItemRule } from "naive-ui";
import {
  maxLengthMessage,
  requiredMessage,
} from "@/composables/validationMessages";

const costingClassificationVerificationRules = (
  field: string,
  required = true
): FormItemRule[] => {
  return [
    {
      required,
      validator(_rule: FormItemRule, value: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
          if (required && !value) {
            reject(Error(`*${requiredMessage(field)}`));
          } else if (value.length > 25) {
            reject(Error(`*${maxLengthMessage(field, 25)}`));
          } else {
            resolve();
          }
        });
      },
      trigger: ["input", "blur"],
    },
  ];
};

export default costingClassificationVerificationRules;
