const useScrollToError = (errorClass = "sticky-error--wrapper"): void => {
  const errors = document.getElementsByClassName(errorClass);
  const scrollElement = errors[0];
  if (errors.length === 0) {
    return;
  }
  scrollElement.scrollIntoView({
    behavior: "smooth",
  });
};

export default useScrollToError;
