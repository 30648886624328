import { QuoteAdminStatuses } from "@/enums/QuoteAdminStatuses";

export const isQuoteUneditable = (
  status: number | undefined,
  isDeleted = false
): boolean => {
  if (isDeleted) {
    return true;
  }
  if (status === undefined) {
    return false;
  }
  return status >= QuoteAdminStatuses.Quoted;
};
